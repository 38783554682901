.sale-summary {
  .section {
    &__label {
      font-weight: 600;
    }
    &__content {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
