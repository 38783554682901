.user {
  &__card {
    .attribute {
      &:nth-child(4) {
        grid-column: 1 / -1;
      }

      &__value {
        @include ellipsis;
      }
    }
  }
}
