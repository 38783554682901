.sidebar {
  @include gap(grid, 1.5rem);
  position: relative;
  padding: 1.5rem 0;
  grid-template-rows: repeat(2, min-content) 1fr;
  align-content: start;

  &__collapse {
    @include absolute;
    @include centralize;
    top: 0.85rem;
    right: -0.9rem;
    bottom: 7rem;
    width: 1.8rem;
    height: 1.8rem;
    background-color: $color-primary;
    border: 0.1rem solid $color-white;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) -0.2rem 0 1.3rem 0.3rem,
      inset rgba(255, 255, 255, 0.15) 0.4rem 0 0.8rem -0.1rem;
    transform: rotate(180deg);
    transition: box-shadow 200ms, background-color 200ms, fill 200ms;
    opacity: 0;
    cursor: pointer;

    svg {
      fill: $color-white;
      transform: scale(0.9);
    }

    &:hover {
      background-color: $color-white;
      box-shadow: rgba($color-primary-dark, 0.8) 0 0.3rem 1rem 0.1rem;

      svg {
        fill: $color-primary;
      }
    }
  }

  &:hover &__collapse {
    opacity: 1;
  }

  &__footer {
    align-self: self-end;
  }
}
