.tab {
  @include gap(flex, 0.75rem);
  line-height: 1.8rem;
  cursor: pointer;

  &__label {
    color: $color-primary;
    transition: color 300ms;
  }

  &__count {
    padding: 0 0.6rem;
    color: $color-white;
    font-size: 1.15rem;
    font-weight: 500;
    background-color: $color-primary;
    border-radius: $border-radius-lg;
    transition: background-color 300ms;
  }

  &--inactive &__label {
    color: $color-secondary;
  }

  &--inactive &__count {
    background-color: $color-secondary;
  }
}
