.product {
  &__card {
    .attribute {
      grid-column: 1 / span 2;

      &:nth-child(1) > .attribute__value {
        font-size: 2rem;
        color: $color-primary;
        font-weight: 500;
      }

      &:nth-child(4) > .attribute__value {
        font-size: 2rem;
      }

      &:nth-child(4) {
        grid-column: 1 / span 1;
      }

      &__value {
        @include ellipsis;
      }
    }
  }
}

.product-detail {
  .section {
    &__content {
      grid-template-columns: repeat(4, 1fr);
    }

    &:nth-child(1) {
      .attribute {
        &:nth-child(3),
        &:nth-child(4) {
          grid-column: 1 / -1;
        }
      }
    }
  }
}
