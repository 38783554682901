.upload {
  @include gap(grid, 1rem);
  width: 100%;
  margin-bottom: 2rem;
  align-items: start;

  &__label {
    font-size: 1.3rem;
    font-weight: 500;

    &--optional {
      color: $color-grey;
      font-weight: 400;
    }
  }

  &__field {
    @include centralize;
    width: 100%;
    height: 15rem;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23e4e7f0' stroke-width='2' stroke-dasharray='4%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: $border-radius-md;
    cursor: pointer;

    &--collapse {
      height: 7rem;
    }

    &--drag {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%236562ff' stroke-width='2' stroke-dasharray='4%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      box-shadow: inset rgba($color-primary, 0.15) 0 0 1rem 0.1rem;
    }
  }

  &__placeholder {
    color: $color-grey-dark;
    font-size: 1.3rem;
    cursor: pointer;
  }

  &__highlight {
    color: $color-primary;
    font-weight: 600;
    cursor: pointer;
  }

  &__error {
    color: #e74c3c;
    font-size: 1.3rem;
    animation: error-message-animation 500ms both;
  }
}
