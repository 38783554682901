.dashboard {
  display: grid;
  grid-template-columns: 24rem 1fr;
  grid-template-rows: 5.5rem minmax(0, 1fr);
  transition: grid-template-columns 100ms;

  &--collapse {
    grid-template-columns: 6rem 1fr;
  }

  &__page-header {
    grid-column: 1 / -1;
  }

  &__sidebar {
    background-color: $color-primary;
  }

  &__header {
    @include gap(grid, 1rem);
    padding: 2rem 2rem 0.5rem 2rem;
    grid-template-columns: max-content 1fr;
  }

  &__tab {
    @include gap(grid, 1rem 3rem);
    margin: 0.75rem 0;
    grid-template-columns: inherit;
    align-content: start;
  }

  &__information {
    @include centralize;
    width: 100%;
    grid-template-columns: min-content 1fr;
    grid-column: 1 / -1;
    justify-content: start;
    color: $color-grey;
    font-size: 1.3rem;
    gap: 0.5rem;

    &--error {
      color: $color-warning-font;
    }
  }

  &__loading {
    @include centralize;
    width: 1.6rem;
    height: 1.6rem;
    overflow: hidden;
    animation: loading-animation 50ms both;

    img {
      width: 4.8rem;
      height: 4.8rem;
      content: url(../../../Assets/Reusable/loading-colored.png) !important;
      animation: rotation 700ms infinite linear;
    }
  }

  &__filter {
    @include gap(flex, 2rem);
    justify-content: right;
  }

  &__content {
    display: grid;
    align-content: start;
    grid-template-rows: min-content 1fr;
  }

  .coming-soon {
    margin-top: 20rem;
    text-align: center;
    font-size: 2rem;
    color: $color-grey;
  }
}
