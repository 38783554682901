h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

h2 {
  font-size: 1.7rem;
  font-weight: 400;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

h4 {
  font-size: 1.35rem;
  font-weight: 600;
}

.label {
  font-size: 1.25rem;
  font-weight: 500;
}

.hint {
  color: $color-grey;
  font-size: 1.3rem;
  line-height: 2rem;
}
