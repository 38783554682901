.modal {
  position: relative;
  min-height: 14rem;
  min-width: 14rem;
  border-radius: $border-radius-lg;
  background-color: $color-white;
  overflow: hidden;

  &__overlay {
    position: fixed;
    display: flex;
    top: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.2rem);
  }

  &__container {
    @include gap(grid, 3rem);
    width: 50vw;
    padding: 4rem 5rem;
  }

  &__header {
    @include gap(grid, 0.8rem);
    justify-items: center;
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__info {
    font-size: 1.35rem;
    color: $color-secondary-light;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;

    &:hover {
      background-color: rgba($color-fail-font, 0.075);
    }

    .close-icon {
      fill: $color-grey;
      stroke-width: 0.02rem;
      transition: fill 100ms;
      transform: scale(2.1);
    }

    &:hover .close-icon {
      fill: $color-fail-font;
      stroke: $color-fail-font;
    }
  }
}
