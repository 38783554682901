.nav {
  @include gap(grid, 1.5rem);
  height: 4.8rem;
  padding: 0 1.5rem;
  grid-template-columns: max-content 1fr;
  align-items: center;
  color: #fff;
  transition: background-color 300ms;
  cursor: pointer;

  &:hover {
    background-color: rgba($color-primary-dark, 0.6);
  }

  &--active {
    background-color: $color-primary-dark !important;
  }

  &__icon {
    @include centralize;
    width: 3rem;
    height: 3rem;
    margin-left: 2rem;

    img {
      width: auto;
      height: 2.8rem;
    }
  }
}
