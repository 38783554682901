@keyframes error-message-animation {
  0% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: 5.4rem;
    opacity: 1;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.33);
  }
  to {
    transform: rotate(359deg) scale(0.33);
  }
}

@keyframes loading-animation {
  0% {
    width: 0;
    transform: scaleX(0);
    opacity: 0;
  }

  100% {
    width: 2rem;
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes card-active {
  0% {
    border: 0.1rem solid $color-white;
    box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.1) 0px 2px 6px 2px;
  }

  50% {
    border: 0.1rem solid $color-primary;
    box-shadow: rgba($color-primary, 0.5) 0px 3px 25px 0px;
  }

  100% {
    border: 0.1rem solid $color-white;
    box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.1) 0px 2px 6px 2px;
  }
}
