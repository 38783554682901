@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 1.4rem;
    height: 1.4rem;
  }

  &::-webkit-scrollbar-button {
    display: block;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: $border-color;
    border: 0.6rem solid $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-light;
    border: 0.35rem solid $color-white;
    border-radius: $border-radius-lg;

    &:hover {
      background-color: $color-grey;
      border: 0;
    }
  }
}

@mixin scrollbar-no-thumb {
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
  }
}

@mixin ellipsis($lineLength: null) {
  @if $lineLength != null {
    display: -webkit-box;
    -webkit-line-clamp: $lineLength;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

@mixin background-image($pos: center, $size: contain, $repeat: no-repeat) {
  background-position: $pos;
  background-size: $size;
  background-repeat: $repeat;
}

@mixin centralize {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin absolute {
  content: "";
  position: absolute;
}

@mixin gap($type: flex, $space: 0) {
  @if $type == "flex" {
    display: flex;
    gap: $space;
  } @else if $type == "grid" {
    display: grid;
    gap: $space;
  }
}
