.sale-data {
  &__output {
    @include gap(grid, 1rem);
    align-content: start;
  }

  &__header {
    @include gap(grid, 1rem);
    grid-template-columns: max-content 1fr;
  }

  &__output &__header {
    grid-template-columns: repeat(2, max-content) 1fr max-content;
    align-items: center;
  }

  &__title {
    display: flex;
    height: 3.2rem;
    align-items: center;
  }

  &__toolbar {
    @include gap(grid, 1rem);
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__toolbar-action {
    @include gap(flex, 1rem);
    grid-column: 3 / span 1;
  }

  &__validation-status {
    @include gap(flex, 1.5rem);
    height: 3.5rem;
    align-items: center;
  }

  &__status-message {
    line-height: 1.8rem;
    padding: 0.75rem 1rem 0.75rem 3.25rem;
    font-size: 1.3rem;
    font-weight: 500;
    border-radius: $border-radius-sm;
    @include background-image(1rem center, 1.5rem, no-repeat);

    &--success {
      background-color: rgba($color-success-font, 0.15);
      color: $color-success-font;
      background-image: url("../../../Assets/Reusable/valid.svg");
    }

    &--error {
      background-color: #fff1ef;
      color: $color-fail-font;
      background-image: url("../../../Assets/Reusable/error.svg");
    }
  }

  &__result {
    width: calc(100% + 1.5rem);
    margin-left: -2rem;
    overflow-y: hidden;

    .table {
      &__data {
        margin: 0.2rem 1rem 0.2rem 2rem;
      }
    }

    &::-webkit-scrollbar-button {
      width: 0.75rem;
    }
  }

  &__placeholder {
    padding: 2rem 2rem 5rem 2rem;
    border: 0.2rem solid $border-color;
    border-radius: $border-radius-md;
  }

  &__loading {
    @include centralize;
    font-weight: 500;
    gap: 0.5rem;
  }

  &__loading-icon {
    @include centralize;
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
    animation: loading-animation 50ms both;

    img {
      width: 5.5rem;
      height: 5.5rem;
      content: url(../../../Assets/Reusable/loading-colored.png) !important;
      animation: rotation 700ms infinite linear;
    }
  }
}
