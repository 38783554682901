.customer {
  &__card {
    .attribute {
      &:nth-child(5) {
        grid-column: 1 / -1;
      }

      &__value {
        @include ellipsis;
      }
    }
  }
}

.customer-detail {
  .section {
    &__content {
      grid-template-columns: repeat(2, 1fr);
    }

    .attribute {
      &:nth-child(5) {
        grid-column: 1 / -1;
        word-break: break-all;
      }
    }

    &:nth-child(3) {
      grid-column: 1 / -1;

      .section {
        &__content {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}
