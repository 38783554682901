.template {
  &__card {
    .attribute {
      &:nth-child(1) {
        grid-column: 1 / -1;
      }

      &__value {
        @include ellipsis;
      }
    }
  }

  &::-webkit-scrollbar-button {
    height: 2rem;
  }
}

.template-detail {
  &__meta {
    .section {
      &:nth-child(2) {
        .section__content {
          .attribute {
            &:nth-child(1),
            &:nth-child(2) {
              grid-column: 1 / span 2;
            }
          }
        }
      }

      &:nth-child(3) {
        .section__content {
          grid-template-columns: unset;

          .card {
            min-height: unset;
          }
        }
      }
    }
  }

  .label-system {
    .setting {
      .form {
        @include gap(grid, 4rem 2rem);
        grid-template-columns: repeat(5, 1fr);

        .input {
          margin-bottom: 0;
        }
      }

      .setting--validation {
        .form {
          @include gap(grid, 2rem);
          grid-template-columns: repeat(5, 1fr);
          align-items: center;

          #button-deleteColumn,
          #button-insertColumn {
            width: 2.4rem;
            height: 2.4rem;
            margin-top: 2.25rem;
            grid-column: 3 / span 1;
            grid-row: 1 / span 1;
            border-radius: 50%;
            opacity: 0;
            transition: background-color 100ms, box-shadow 100ms, opacity 100ms;
          }

          &:hover #button-deleteColumn,
          &:hover #button-insertColumn {
            opacity: 1;
          }

          #button-deleteColumn {
            margin-left: 5rem;
            background-color: rgba($color-fail-font, 0.075);

            .close-icon {
              fill: $color-fail-font;
              stroke: $color-fail-font;
              stroke-width: 0.1rem;
              transform: scale(1.3);
            }

            &:hover {
              background-color: $color-fail-font;
              box-shadow: rgba($color-fail-font, 0.3) 0px 1px 2px 0px,
                rgba($color-fail-font, 0.3) 0px 2px 6px 2px;
            }

            &:hover .close-icon {
              fill: $color-white;
              stroke: $color-white;
            }
          }

          #button-insertColumn {
            margin-left: 0.5rem;
            background-color: rgba($color-primary, 0.075);

            .add-icon {
              fill: $color-primary;
              stroke-width: 0.05rem;
              stroke: $color-primary;
              transform: scale(1.3);
            }

            &:hover {
              background-color: $color-primary;
              box-shadow: rgba($color-primary, 0.3) 0px 1px 2px 0px,
                rgba($color-primary, 0.3) 0px 2px 6px 2px;
            }

            &:hover .add-icon {
              fill: $color-white;
              stroke: $color-white;
            }
          }

          &:nth-child(2) {
            #button-deleteColumn {
              display: none;
            }
          }
        }
      }
    }
  }
}
