.file {
  @include gap(grid, 0.75rem);
  min-height: unset;
  grid-template-columns: 1fr max-content;

  &__title {
    font-weight: 500;
  }

  &__detail-list {
    display: grid;
    grid-column: 1 / span 1;
    grid-template-columns: repeat(3, 1fr);
    color: $color-grey-dark;
    font-size: 1.2rem;
  }

  &__input {
    display: none;
  }

  &__action-list {
    @include gap("flex", 0.75rem);
    @include centralize;
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
  }

  &__action {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 100ms, box-shadow 100ms;

    &--open {
      &:hover {
        background-color: $color-primary;
        box-shadow: rgba($color-primary, 0.3) 0px 1px 2px 0px,
          rgba($color-primary, 0.3) 0px 2px 6px 2px;
      }
    }

    &--close {
      &:hover {
        background-color: $color-fail-font;
        box-shadow: rgba($color-fail-font, 0.3) 0px 1px 2px 0px,
          rgba($color-fail-font, 0.3) 0px 2px 6px 2px;
      }
    }

    .view-file-icon,
    .open-file-icon,
    .close-icon {
      fill: $color-grey;
      transition: fill 100ms;
    }

    .open-file-icon {
      transform: scale(1.1);
    }

    .close-icon {
      transform: scale(1.5);
    }

    &:hover .view-file-icon,
    &:hover .open-file-icon,
    &:hover .close-icon {
      fill: $color-white;
    }

    &:hover .view-file-icon,
    &:hover .open-file-icon {
      stroke: none;
    }

    &:hover .close-icon {
      stroke: $color-white;
    }
  }
}
