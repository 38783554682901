.sale-submission {
  .confirmation {
    position: relative;
    gap: 1.25rem;
    background-color: unset;
    backdrop-filter: unset;

    &__title {
      font-size: 2rem;
    }

    &__info {
      padding: 0 10%;
      line-height: 2rem;
    }

    .button {
      margin-top: 1rem;
    }
  }

  &--fail {
    .confirmation {
      &__icon {
        height: 4.25rem;

        img {
          height: 95%;
        }
      }
    }
  }
}
