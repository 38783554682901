.customer-template-detail {
  &__field {
    grid-column: 1 / -1;
    grid-row: 1 / span 1;

    .input {
      margin-bottom: 1rem;

      &__label {
        color: $color-grey-dark;
      }
    }
  }
}
