.region {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  cursor: crosshair;

  &__card {
    position: absolute;
    border: 0.1rem solid rgba($color-grey, 0.8);
    transition: background-color 100ms, border-top-left-radius 100ms;

    &:hover {
      border-top-left-radius: 0;
      z-index: 1;
    }
  }

  &__label {
    display: flex;
    position: absolute;
    top: -1.6rem;
    left: -0.08rem;
    height: 1.8rem;
    line-height: 1.8rem;
    color: $color-white;
    font-size: 1.15rem;
    font-weight: 500;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    white-space: nowrap;
    opacity: 0;
    overflow: hidden;
    transition: top 150ms, opacity 50ms;
  }

  &__card:hover &__label {
    top: -1.8rem;
    opacity: 1;
  }

  &__text {
    padding: 0 0.5rem;
    background-color: rgba($color-grey, 0.15);
    opacity: 0.95;
  }

  &__remove {
    @include centralize;
    position: relative;
    width: 1.8rem;
    height: 100%;
    cursor: pointer;
    transition: background-color 150ms, box-shadow 150ms;

    svg {
      fill: $color-white;
      stroke: $color-white;
      transition: fill 150ms, stroke 150ms;
    }

    &:hover {
      box-shadow: unset !important;
      background-color: $color-white !important;
      border-radius: 0.3rem;
      border-left-color: $color-white !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  @mixin region-card($index, $color) {
    &__card--#{$index} {
      background-color: rgba($color, 0.08);
      border: 0.1rem solid rgba($color, 0.7);
    }

    &__card--#{$index}#{&}__card--active {
      background-color: rgba($color, 0.25);
      border: 0.1rem solid rgba($color, 0.9);
    }

    &__card--#{$index} &__label &__text {
      background-color: $color;
    }

    &__card--#{$index} &__remove {
      background-color: $color;
      box-shadow: inset 0 0 0.5rem 0 rgba($color-black, 0.2);

      &:hover {
        border: 0.1rem solid $color;

        svg {
          fill: $color;
          stroke: $color;
        }
      }
    }

    &__card--#{$index} .resize__control-button {
      background-color: mix($color-white, $color, 50%);
      border: 0.1rem solid $color;
    }
  }

  $n: length($color-palette);

  @for $i from 1 through $n {
    $color-name: nth(map-keys($color-palette), $i);
    $color-value: map-get($color-palette, $color-name);

    @include region-card($i, $color-value);
  }
}
