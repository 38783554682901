.loader {
  @include centralize;
  position: relative;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%);

  img {
    position: relative;
    top: -30%;
    width: 2.4rem;
    height: 2.4rem;
  }
}
