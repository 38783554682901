.template-detail {
  @include gap(grid, 4rem);
  width: calc(100% - 0.5rem);
  padding: 0 1.5rem 0 2rem;

  &__meta {
    @include gap(grid, 3rem);
    grid-template-columns: repeat(2, 1fr);
    align-content: start;

    & > :nth-child(2) {
      grid-column: 1 / span 1;
    }

    & > :nth-child(3) {
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
    }
  }
}
