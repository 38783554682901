.h-100 {
  height: 100vh;
}

.h-100-perc {
  height: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.h-remaining {
  display: grid;
  height: 100%;
  grid-template-rows: min-content minmax(0, 1fr);
}

img {
  width: 100%;
  height: 100%;
}

.scroll {
  @include scrollbar;
  overflow: auto;

  &--active {
    width: calc(100% - 0.6rem);
  }

  &--active &__child {
    padding-right: 0.8rem;
  }
}
