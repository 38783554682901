html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  &::selection {
    background-color: $color-primary;
    color: $color-white;
  }
}

body {
  line-height: normal;
  color: $color-secondary;
  font-family: $primary-font-family;
  font-size: 1.35rem;
  overflow: hidden;
}

input,
textarea,
button {
  font-family: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

img {
  user-select: none;
}

ul,
ol,
li {
  padding: 0;
  list-style-type: none;
}

a {
  all: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 2.5rem white inset !important;
  ~ .form__label {
    display: none;
  }
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#root {
  z-index: 1;
}

#modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1300;
}
