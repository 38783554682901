.product {
  &__list {
    padding: 0 2rem;
    margin: 2rem 0;
    @include gap(grid, 2rem);
    grid-template-columns: repeat(3, 1fr);
  }

  &__card {
    @include gap(grid, 1.75rem 1.5rem);
    grid-template-columns: repeat(1, 1fr);

    .button {
      grid-column: 2 / -1;
      justify-self: right;
      align-self: self-end;
    }
  }
}
