.pdf {
  &__viewer {
    display: grid;
    grid-template-rows: min-content minmax(0, 1fr);

    &--view {
      width: 80vw;
      height: 90vh;
    }
  }

  &__header {
    position: relative;
    @include gap(grid, 1rem);
    grid-template-columns: max-content 1fr max-content;
    height: 6rem;
    padding: 0 1.5rem;
    align-items: center;
    background-color: $color-white;
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: $border-radius-sm;
    box-shadow: 1px 1px 41px -17px #00000050;
    z-index: 1;
  }

  &__meta {
    @include gap(flex, 0.5rem);
    align-items: center;
  }

  &__icon {
    width: 2.5rem;
    height: 3rem;
    background-image: url("../../../Assets/Reusable/pdf.svg");
    @include background-image(center, 1.5rem, no-repeat);
  }

  &__content {
    height: 100%;
    overflow: hidden;
  }

  &__scroll {
    @include gap(grid, 2rem);
    width: calc(100% - 0.5rem);
    height: auto;
    min-height: 10rem;
    padding: 1.5rem 1rem 2rem 1.5rem;
    justify-items: center;

    &::-webkit-scrollbar-button {
      width: 0.8rem;
      height: 0.8rem;
    }

    &::-webkit-scrollbar-track {
      border: 0.5rem solid mix($color-white, $color-grey, 95%);
    }
  }

  &__content &__scroll {
    height: 100%;
  }

  &__page {
    position: relative;
    --scale-factor: none !important;

    canvas {
      box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
      border-radius: $border-radius-sm;
    }
  }

  &__action-list {
    @include gap("flex", 0.75rem);
    @include centralize;
    grid-column: 3 / span 1;
  }

  &__action {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 100ms, box-shadow 100ms;

    &--open {
      &:hover {
        background-color: $color-primary;
        box-shadow: rgba($color-primary, 0.3) 0px 1px 2px 0px,
          rgba($color-primary, 0.3) 0px 2px 6px 2px;
      }
    }

    &--close {
      &:hover {
        background-color: $color-fail-font;
        box-shadow: rgba($color-fail-font, 0.3) 0px 1px 2px 0px,
          rgba($color-fail-font, 0.3) 0px 2px 6px 2px;
      }
    }

    .open-file-icon,
    .close-icon {
      fill: $color-grey;
      transition: fill 100ms;
    }

    .open-file-icon {
      transform: scale(1.1);
    }

    .close-icon {
      transform: scale(1.4);
    }

    &:hover .open-file-icon,
    &:hover .close-icon {
      fill: $color-white;
    }

    &:hover .open-file-icon {
      stroke: none;
    }

    &:hover .close-icon {
      stroke: $color-white;
    }
  }

  &__zoom {
    @include gap(flex, 0.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  &__zoom-value {
    width: 4rem;
    text-align: center;
  }

  .page {
    width: auto !important;
    height: auto !important;

    .canvasWrapper {
      display: grid;
    }
  }
}
