.breadcrumb {
  @include gap(flex, 1.5rem);
  line-height: 1.8rem;
  align-items: center;

  &__label {
    &--clickable {
      color: $color-primary;
      cursor: pointer;
    }

    &--disabled {
      color: $color-grey-light;
    }
  }

  &__children {
    @include gap(flex, 1.5rem);
    align-items: center;

    &::before {
      content: "";
      position: relative;
      width: 1rem;
      height: 2rem;
      background-image: url("../../../Assets/Reusable/arrow.svg");
      @include background-image(center, 1rem, no-repeat);
    }
  }
}
