$primary_font_family: "Inter", sans-serif;

$color-primary: #3b37ff;
$color-primary-light: #6562ff;
$color-primary-dark: #2420e2;

$color-secondary: #1b1b24;
$color-secondary-light: #525269;
$color-secondary-dark: #1b1b24;

$color-white: #fff;
$color-black: #000;

$color-grey: #9797b8;
$color-grey-dark: #707f8f;
$color-grey-light: #cacae5;

$color-hover: #eeeeff;

$color-disable: #bfbfda;

$color-success-font: #2ecc71;
$color-success-font-dark: #2ecc71;
$color-success-border: #2ecc71;
$color-success-background: #e5fff0;

$color-fail-font: #e74c3c;
$color-fail-font-dark: #e74c3c;
$color-fail-border: #ff604f;
$color-fail-background: #fff1ef;

$color-warning-font: #f39c12;
$color-warning-font-dark: #f39c12;
$color-warning-border: #f39c12;
$color-warning-background: #fff6e9;

$color-palette: (
  color-palette-1: #ee5656,
  color-palette-2: #58a3ff,
  color-palette-3: #ffa246,
  color-palette-4: #11b76a,
  color-palette-5: #6128ce,
  color-palette-6: #f36ce1,
  color-palette-7: #00d4d5,
  color-palette-8: #0abee3,
);

$border-color: #e4e7f0;
$border-color-focus: #798efc;
$border-primary: 0.1rem solid $border-color;

$border-radius-lg: 1.3rem;
$border-radius-md: 1rem;
$border-radius-sm: 0.7rem;
$border-radius-xs: 0.6rem;

$box-shadow: 0 0 1.5rem #d1dfeb;

$gutter-horizontal: 1rem;
$gutter-vertical: 1rem;
