.header {
  @include gap("grid", 3rem);
  align-items: center;
  grid-template-columns: max-content 1fr repeat(2, max-content);
  box-shadow: 1px 1px 41px -17px #00000050;

  &__info {
    grid-column: 3 / span 1;

    &--align-right {
      text-align: end;
    }
  }

  &__action {
    @include gap(flex, 2rem);
    margin-right: 2rem;
    grid-column: 4 / span 1;
  }
}
