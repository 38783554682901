.sale-additional-field {
  @include gap(grid, 2rem);
  margin-bottom: 2rem;

  &__header {
    @include gap(grid, 1rem);
    grid-template-columns: max-content 1fr;
    align-items: center;
  }

  .form {
    @include gap("grid", 4rem 2rem);
    grid-template-columns: repeat(2, 1fr);
  }
}
