.attribute {
  @include gap(grid, 0.5rem);
  align-content: start;

  &__label {
    color: $color-grey-dark;
  }

  &__value {
    position: relative;
    line-height: 2.2rem;

    &--overflow {
      text-overflow: unset !important;
      white-space: unset !important;
      overflow: unset !important;
    }

    &--see-more {
      display: none;
      @include absolute;
      right: 0;
      bottom: 0;
      padding-left: 5rem;
      font-size: 1.3rem;
      font-weight: 500;
      color: $color-grey-light;
      background-image: linear-gradient(
        to right,
        rgba($color-white, 0) 0%,
        rgba($color-white, 1) 35%,
        $color-white 100%
      );
      cursor: pointer;

      &:hover {
        color: $color-primary;
      }
    }
  }
}
