.modal {
  .template-basic-info {
    .form {
      @include gap(grid, 1.2rem 3rem);
      grid-template-columns: repeat(2, 1fr);

      & > .upload {
        grid-column: 1 / span 2;
      }

      .dropdown {
        margin-bottom: 2rem;

        .input {
          margin-bottom: 0;
        }
      }
    }

    &--sale .form {
      & > .button {
        grid-column: 2 / span 1;
        justify-self: end;
      }

      & > .button,
      & > .form__error {
        height: 3.4rem;
      }
    }

    &--template .form {
      & > :nth-child(3) {
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;

        textarea {
          height: 10rem;
        }
      }

      & > :nth-child(4) {
        .upload__field {
          height: 10rem;

          &--collapse {
            height: 6.5rem;
          }
        }
      }

      & > .button {
        grid-column: 2 / span 1;
        justify-self: end;
      }
    }
  }

  .create-user .form {
    @include gap(grid, 1.2rem 3rem);
    grid-template-columns: repeat(2, 1fr);

    .button {
      height: 3.4rem;
      grid-column: 1 / span 2;
      justify-self: end;
    }
  }

  .upload {
    .card {
      min-height: unset;
      padding: 1.25rem 1.5rem;
    }

    .upload__input {
      .button {
        width: 100%;
        height: auto;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
