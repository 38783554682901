.card {
  position: relative;
  min-height: 10rem;
  padding: 1.75rem 1.5rem;
  border: 0.1rem solid $color-white;
  border-radius: $border-radius-sm;
  transition: border 200ms, box-shadow 200ms;
  box-shadow: rgba(60, 64, 67, 0.1) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.1) 0px 2px 6px 2px;
  overflow: hidden;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.25) 0px 7px 29px 0px;
  }

  &--active {
    border: 0.1rem solid $color-primary;
    box-shadow: rgba($color-primary, 0.5) 0px 3px 15px 0px;
    animation: card-active 800ms forwards 3;
  }
}
