.email {
  &__list {
    padding: 0 2rem;
    margin: 2rem 0;
    @include gap(grid, 2rem);
    grid-template-columns: 1fr;
  }

  &__card {
    @include gap(grid, 1.75rem 1.5rem);
    grid-template-columns: 1fr 2fr repeat(2, 1fr) 1fr;
  }
}
