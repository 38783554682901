.user {
  &__list {
    padding: 0 2rem;
    margin: 2rem 0;
    @include gap(grid, 2rem);
    grid-template-columns: repeat(4, 1fr);
  }

  &__card {
    @include gap(grid, 1.75rem 1.5rem);
    grid-template-columns: min-content 1fr;
  }

  &__thumbnail {
    width: auto;
    height: 100%;
    margin: 0;
    grid-row: 1 / span 2;
    background-color: unset;

    img {
      width: auto;
      height: 100%;
      border: 0.2rem solid $color-white;
      border-radius: 50%;
    }
  }

  &__avatar-initial {
    @include centralize;
    width: 10rem;
    height: 10rem;
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: $color-white;
    background-color: #7d7aff;
    border-radius: 50%;
  }
}
