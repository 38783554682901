@mixin label-palette($color) {
  background-color: rgba($color, 0.15);

  &::before {
    background-color: $color;
  }
}

@mixin action-list-region($index, $color) {
  & > :nth-child(#{$index}) {
    @include label-palette($color);

    .button__completed svg {
      fill: #2ecc71;
    }

    &:hover {
      box-shadow: 0.2rem 0.8rem 1.5rem -1rem $color;
    }
  }
  & > :nth-child(#{$index}).button--active {
    background-color: $color;
    box-shadow: 0.2rem 0.8rem 1.5rem -1rem $color;

    .button__completed svg {
      fill: $color-white;
    }
  }
}

@mixin action-list-area($index, $color) {
  &--area#{&}--#{$index} {
    & > button {
      @include label-palette($color);
      border: 0.1rem solid rgba($color, 0.7);

      .button__completed svg {
        fill: #2ecc71;
      }
    }
  }

  &--area#{&}--#{$index} .button--active {
    background-color: $color;

    .button__completed svg {
      fill: white;
    }
  }
}

.label-system {
  @include gap(grid, 4rem);

  &__control-list {
    @include gap(grid, 3rem);
  }
  &__title {
  }

  &__control {
    @include gap(grid, 1rem);
  }

  &__label {
    color: $color-grey;
  }

  &__action-list {
    @include gap(flex, 2rem);

    $n: length($color-palette);

    @for $i from 1 through $n {
      $color-name: nth(map-keys($color-palette), $i);
      $color-value: map-get($color-palette, $color-name);

      &--region {
        @include action-list-region($i, $color-value);
      }

      @include action-list-area($i, $color-value);
    }

    &--region {
      .button--active {
        color: $color-white;

        &::before {
          display: none;
        }
      }
    }

    &--area {
      .button {
        &::before {
          display: none;
        }
        &--active {
          color: $color-white;
        }
      }
    }
  }

  &__action {
  }
}
