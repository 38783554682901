.table {
  @include gap(flex, 2rem);
  width: 100%;
  height: auto;
  min-height: 10rem;
  max-height: 45rem;
  flex-direction: column;
  padding: 0.2rem;
  margin-bottom: 1.5rem;
  border-radius: $border-radius-md;

  &__tbody {
    @include gap(grid, 0.2rem);
  }

  &__data {
    display: grid;
    padding: 0 0.2rem 0.2rem 0.2rem;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__row {
    @include gap(flex, 0.2rem);
    position: relative;

    &--pin {
      @include gap(flex, 0.2rem);
      position: sticky;
      left: 2.4rem;
      top: 0;
      z-index: 2;

      &::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        box-shadow: -1.5rem 0 0 1.5rem $color-white;
      }
    }

    &--header {
      @include gap(flex, 0.2rem);
      position: sticky;
      left: 0.2rem;
      top: 0.2rem;
      margin-bottom: 0.2rem;
      z-index: 3;
      box-shadow: 0 1rem 2rem -0.8rem rgba($color-black, 0.2);
    }

    &--regular {
      @include gap(flex, 0.2rem);
      position: relative;
    }

    &--active .table__cell {
      background-color: mix($color-white, $color-primary, 95%);
    }
  }

  &__cell {
    position: relative;
    @include centralize;
    width: 20rem;
    height: 100%;
    padding: 1.5rem;
    line-height: 2rem;
    background-color: $color-white;
    text-align: center;
    box-shadow: 0 0 0 0.2rem $border-color;

    &:hover {
      .tooltip,
      .validate__trigger {
        opacity: 1;
        visibility: visible;
      }
    }

    &--th {
      font-weight: 600;
    }

    &--map {
      width: 7.5rem;
      min-width: 7.5rem;
    }

    &--active {
      z-index: 2;
      box-shadow: 0 0 0 0.2rem $color-primary;
    }

    .form {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      .input {
        display: block;
        height: 100%;
        margin: 0;

        .input__field {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 0;
          text-align: center;
          box-shadow: 0 0 0 0.15rem $color-primary;
        }
      }
    }
  }

  &__row .dropdown {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &__list {
      top: 100%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 54px 55px,
        rgba(0, 0, 0, 0.05) 0px -12px 30px, rgba(0, 0, 0, 0.02) 0px 4px 6px,
        rgba(0, 0, 0, 0.07) 0px 12px 13px, rgba(0, 0, 0, 0.01) 0px -3px 5px;
    }
  }

  &__row .dropdown &__cell {
    width: 100%;
  }
}
