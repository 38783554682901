.parser {
  @include gap(grid, 2rem);
  margin-bottom: 3rem;
  grid-template-columns: repeat(2, 1fr);

  &__input,
  &__output {
    @include gap(grid, 2rem);
    align-content: start;
  }
  &__header {
    @include gap(grid, 1rem);
    grid-template-columns: max-content 1fr;
  }

  &__output &__header {
    grid-template-columns: max-content 1fr min-content;
    align-items: center;
  }

  &__title {
    display: flex;
    height: 3.2rem;
    align-items: center;
  }

  &__info {
    grid-column: 1 / -1;
    grid-row: 2 / span 1;
  }

  &__drawer {
    display: grid;
    border: 0.2rem solid $border-color;
    border-radius: $border-radius-md;
    background-color: rgba($color-grey, 0.05);
  }

  &__result {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__placeholder {
    padding: 2rem 2rem 5rem 2rem;
    border: 0.2rem solid $border-color;
    border-radius: $border-radius-md;
  }
}
