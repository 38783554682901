.section {
  @include gap(grid, 1rem);
  align-content: start;

  &__label {
    @include gap(flex, 0.5rem);
    align-items: center;
    line-height: 2.4rem;
    font-weight: 500;
  }

  &__edit {
    @include centralize;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    cursor: pointer;

    svg {
      transform: scale(0.95);
    }

    &:hover {
      background-color: $color-hover;

      svg {
        fill: $color-primary;
      }
    }
  }

  &__content {
    @include gap(grid, 2rem);
    grid-template-columns: repeat(2, 1fr);
  }
}
