.validate {
  background-color: #f2f5ff;

  &--editable {
    background-color: $color-white;
  }

  &--message-code {
    $n: length($color-palette);

    @mixin validate-message-code($i) {
      $color-name: nth(map-keys($color-palette), $i);
      $color-value: map-get($color-palette, $color-name);
      background-color: mix(
        rgba(255, 255, 255, 1),
        $color-value,
        65%
      ) !important;
      color: $color-black;
    }

    @for $i from 1 through $n {
      &-#{$i} {
        @include validate-message-code($i);
      }
    }
  }

  &__trigger,
  &__loading {
    @include absolute;
    top: 0.5rem;
    right: 0.3rem;
    width: 2rem;
    height: 2rem;
    border-radius: 0.3rem;
    border: 0.12rem solid $border-color;
    z-index: 1;
  }

  &__trigger {
    background-color: $color-white;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms;
    cursor: pointer;

    svg {
      transform: scale(0.8);
    }

    &:hover {
      background-color: $color-hover;
      border-color: $color-primary;
      box-shadow: rgba($color-primary, 0.15) 0.2rem 0.2rem 0.4rem;

      svg {
        fill: $color-primary;
      }
    }

    &--visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__loading {
    @include centralize;
    background-color: $color-white;
    overflow: hidden;
    animation: loading-animation 50ms both;

    img {
      width: 4.8rem;
      height: 4.8rem;
      content: url(../../../Assets/Reusable/loading-colored.png) !important;
      animation: rotation 700ms infinite linear;
    }
  }
}
