.button {
  all: unset;
  display: flex;
  height: 3.2rem;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: box-shadow 200ms;
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;

  &__loading {
    @include centralize;
    width: 2rem;
    height: 2rem;
    margin-right: 1.2rem;
    overflow: hidden;
    animation: loading-animation 150ms both;

    img {
      width: 6rem;
      height: 6rem;
      content: url(../../../Assets/Reusable/loading-white.png) !important;
      animation: rotation 700ms infinite linear;
    }
  }

  &--primary {
    color: $color-white;
    background-color: $color-primary;
    border: 0.1rem solid $color-primary;
    border-radius: $border-radius-md;
    box-shadow: 0.2rem 0.8rem 1.5rem -1rem $color-primary;

    &:hover {
      box-shadow: 0 0.5rem 0.3rem -0.4rem $color-primary;
    }
  }

  &--secondary {
    color: $color-primary-dark;
    border: 0.1rem solid $color-primary-dark;
    border-radius: $border-radius-md;
    box-shadow: 0.2rem 0.8rem 1.5rem -1.2rem $color-primary;

    &:hover {
      box-shadow: 0 0.5rem 0.3rem -0.4rem $color-primary;
    }
  }

  &--tertiary {
    height: auto;
    padding: 0;
    justify-content: center;
    align-items: center;
    color: $color-primary-dark;
    text-decoration: underline;
  }

  &--label {
    position: relative;
    color: $color-secondary;
    background-color: rgba($color-grey, 0.15);
    border-radius: $border-radius-sm;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0.5rem;
      height: 100%;
      background-color: rgba($color-grey, 0.8);
      border-top-left-radius: $border-radius-xs;
      border-bottom-left-radius: $border-radius-xs;
    }

    &:hover {
      box-shadow: 0 0.5rem 0.3rem -0.4rem $color-grey;
    }
  }

  &--sm {
    height: 3rem;
    padding: 0 1.5rem;
    font-size: 1.3rem;
    border-radius: $border-radius-sm;
  }

  &--md {
    font-size: 1.35rem;
    border-radius: $border-radius-sm;
  }

  &--lg {
    height: 4rem;
    font-size: 1.4rem;
    border-radius: $border-radius-md;
  }

  &--disable {
    color: $color-grey;
    background-color: $border-color;
    border: 0.1rem solid $border-color;
    box-shadow: none;
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }

  &--icon {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  &--loading {
    padding: 0 2rem 0 1.3rem;
  }

  &__mandatory {
    margin-left: 0.2rem;
    color: $color-fail-font;
  }

  &--active &__mandatory {
    color: $color-white;
  }

  &--completed {
    padding-left: 1.5rem;
  }

  &__completed {
    @include centralize;
    margin-right: 1rem;

    svg {
      fill: $color-white;
    }
  }

  &--theme {
    &-fail {
      background-color: $color-fail-font;
      border-color: $color-fail-font;
      box-shadow: 0.2rem 0.8rem 1.5rem -1rem $color-fail-font;

      &:hover {
        box-shadow: 0 0.5rem 0.3rem -0.4rem $color-fail-font;
      }
    }
  }
}
